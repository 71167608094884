import React from 'react';
import { Layout } from 'antd';
import { Link } from 'react-router-dom'; // Импортируем Link для навигации
import { FacebookOutlined, InstagramOutlined, TwitterOutlined } from '@ant-design/icons'; // Иконки для социальных сетей

const { Footer: AntFooter } = Layout;

const Footer = () => {
  return (
    <AntFooter style={{ textAlign: 'center', background: '#001529', color: '#fff', padding: '40px 20px' }}>
      <div style={{ maxWidth: '1200px', margin: '0 auto', display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap' }}>
        {/* Левая колонка */}
        <div style={{ flex: 1, minWidth: '200px', textAlign: 'left' }}>
          <h3 style={{ color: '#fff' }}>Nutrición para la energía</h3>
          <p>©2024 Created by Jon</p>
        </div>
        
        {/* Центральная колонка с ссылками */}
        <div style={{ flex: 1, minWidth: '200px', textAlign: 'center' }}>
          <h3 style={{ color: '#fff' }}>Enlaces útiles</h3>
          <p>
            <Link to="/privacy-policy" style={linkStyle}>Política de privacidad</Link> <br />
            <Link to="/policy" style={linkStyle}>Policies</Link> <br></br>
            <Link to="/datasecuritypolicy" style={linkStyle}>DataSecurityPolicy</Link> <br></br>
            <Link to="/cookiepolicy" style={linkStyle}>CookiePolicy </Link>
           
          </p>
        </div>
        
        {/* Правая колонка с иконками социальных сетей */}
        <div style={{ flex: 1, minWidth: '200px', textAlign: 'right' }}>
          <h3 style={{ color: '#fff' }}>Síguenos</h3>
          <div>
            <a href="https://www.facebook.com" target="_blank" rel="noopener noreferrer" style={iconStyle}>
              <FacebookOutlined />
            </a>
            <a href="https://www.instagram.com" target="_blank" rel="noopener noreferrer" style={iconStyle}>
              <InstagramOutlined />
            </a>
            <a href="https://www.twitter.com" target="_blank" rel="noopener noreferrer" style={iconStyle}>
              <TwitterOutlined />
            </a>
          </div>
        </div>
      </div>
    </AntFooter>
  );
};

// Стили для ссылок
const linkStyle = {
  color: '#fff',
  textDecoration: 'none',
  fontSize: '16px',
  margin: '5px 0',
  display: 'inline-block',
};

const iconStyle = {
  color: '#fff',
  fontSize: '24px',
  margin: '0 10px',
  transition: 'color 0.3s',
};

// Изменение цвета иконок при наведении
iconStyle[':hover'] = {
  color: '#1890ff',
};

export default Footer;
