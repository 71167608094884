import React, { useState } from 'react';
import { Layout, Typography, Button, Form, Input, Row, Col, Checkbox, message } from 'antd';
import { useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import img1 from '../components/img/photo_2024-09-24_12-29-36.jpg';
import img2 from '../components/img/photo_2024-09-24_14-27-09.jpg';
import img3 from '../components/img/photo_2024-09-30_15-45-10.jpg';
import { CheckCircleOutlined } from '@ant-design/icons';

const { Content } = Layout;
const { Title, Paragraph } = Typography;

// Datos de los productos
const products = [
    {
      id: 1,
      name: 'Proteínas Naturales',
      description: 'Suplemento de proteínas naturales para mejorar el rendimiento y la recuperación.',
      price: '35€',
      image: img1,
      details: 'Este suplemento es rico en proteínas naturales derivadas de fuentes de alta calidad como el suero de leche y las proteínas vegetales. ' + 
              'Ayuda a reparar y reconstruir el tejido muscular después de entrenamientos intensos, acelerando la recuperación. ' + 
              'Es ideal tanto para deportistas de alto rendimiento como para aquellos que buscan mantener una dieta equilibrada. ' + 
              'Contiene todos los aminoácidos esenciales y es bajo en grasas y carbohidratos, lo que lo hace perfecto para cualquier tipo de dieta. ' + 
              'Recomendado tomarlo después del ejercicio o como complemento nutricional diario.',
    },
    {
      id: 2,
      name: 'Vitaminas para Energía',
      description: 'Complejo multivitamínico diseñado para aumentar los niveles de energía durante el día.',
      price: '25€',
      image: img2,
      details: 'Este complejo multivitamínico contiene una mezcla balanceada de vitaminas y minerales que ayudan a combatir la fatiga y aumentar los niveles de energía. ' +
              'Contiene vitaminas del grupo B, vitamina C, magnesio y zinc, que son clave para mantener un metabolismo energético eficiente. ' +
              'Ideal para personas activas, estudiantes, y quienes tienen una vida agitada y necesitan un impulso extra para enfrentar el día. ' +
              'Además de aumentar la energía, apoya la función inmunológica y contribuye al bienestar general. Se recomienda tomarlo por la mañana para obtener mejores resultados.',
    },
    {
      id: 3,
      name: 'Batido Detox',
      description: 'Batido detoxificante hecho a base de ingredientes naturales para un cuerpo saludable.',
      price: '15€',
      image: img3,
      details: 'Este batido detox está formulado con una combinación de superalimentos ricos en antioxidantes, como la espirulina, la chlorella, y el jengibre. ' + 
              'Ayuda a desintoxicar el cuerpo de toxinas acumuladas y mejora la digestión, gracias a su alto contenido en fibra y compuestos naturales. ' + 
              'Es perfecto para personas que quieren iniciar un programa detox o simplemente mejorar su salud general. ' + 
              'Este batido no solo limpia el cuerpo, sino que también proporciona un impulso de energía natural y apoya el sistema inmunológico. ' + 
              'Recomendado para consumirlo por la mañana o como sustituto de una comida ligera durante el día.',
    },
  ];

const ProductOrder = () => {
  // Obtener el ID de la URL
  const { id } = useParams();

  // Encontrar el producto por ID
  const product = products.find(p => p.id === parseInt(id));

  // Estado para controlar el formulario de pedido
  const [form] = Form.useForm();
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isAgreed, setIsAgreed] = useState(false); // Nuevo estado para контроля согласия
  const [loading, setLoading] = useState(false); // Estado для отображения загрузки

  // Telegram Bot Token и Chat ID (замените на свои)
  const TELEGRAM_BOT_TOKEN = '8191992355:AAGaaFZh07BmEeJzOrjKTzFXAqLwjzGfL9c';
  const TELEGRAM_CHAT_ID = '-4540973525';

  // Процесс отправки данных в Telegram
  const sendToTelegram = async (orderDetails) => {
    const messageText = `
      🛒 *Nuevo Pedido* 🛒
      📦 Producto: ${orderDetails.product.name}
      💵 Precio: ${orderDetails.product.price}
      👤 Nombre: ${orderDetails.name}
      ✉️ Email: ${orderDetails.email}
      📍 Dirección: ${orderDetails.address}
    `;

    try {
      const response = await fetch(`https://api.telegram.org/bot${TELEGRAM_BOT_TOKEN}/sendMessage`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          chat_id: TELEGRAM_CHAT_ID,
          text: messageText,
          parse_mode: 'Markdown',
        }),
      });
      const data = await response.json();
      if (data.ok) {
        message.success('Pedido enviado correctamente!');
        setIsSubmitted(true);
        form.resetFields();
      } else {
        message.error('Hubo un error al enviar el pedido.');
      }
    } catch (error) {
      console.error('Error enviando a Telegram:', error);
      message.error('Error al enviar el pedido.');
    }
  };

  // Procesar el formulario de pedido
  const handleOrderSubmit = (values) => {
    setLoading(true);
    sendToTelegram({ ...values, product })
      .finally(() => setLoading(false));
  };

  if (!product) {
    return <div>Producto no encontrado</div>;
  }

  return (
    <Layout className="layout">
      <Helmet>
        <title>{product.name}</title>
        <meta name="description" content={product.description} />
      </Helmet>

      <Content style={{ padding: '', background: '#f0f2f5' }}>
        <div className="site-layout-content" style={{ padding: '30px 15px', maxWidth: '1200px', margin: '0 auto' }}>
          <Row gutter={[32, 32]}>
            {/* Columna izquierda con imagen e información del producto */}
            <Col xs={24} md={12}>
              <img 
                src={product.image} 
                alt={product.name} 
                style={{ width: '100%', borderRadius: '10px', marginBottom: '20px' }}
              />
              <Title level={2}>{product.name}</Title>
              <Paragraph>{product.details}</Paragraph>
              <Paragraph><strong>Precio: {product.price}</strong></Paragraph>
            </Col>

            {/* Columna derecha con formulario de pedido */}
            <Col xs={24} md={12}>
              <Title level={3} style={{ marginBottom: '20px' }}>Realizar Pedido</Title>
              {!isSubmitted ? (
                <Form form={form} layout="vertical" onFinish={handleOrderSubmit}>
                  <Form.Item
                    name="name"
                    label="Su nombre"
                    rules={[{ required: true, message: 'Por favor, ingrese su nombre' }]}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    name="email"
                    label="Su correo electrónico"
                    rules={[{ required: true, message: 'Por favor, ingrese su correo electrónico' }, { type: 'email', message: 'Correo electrónico no válido' }]}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    name="address"
                    label="Dirección de envío"
                    rules={[{ required: true, message: 'Por favor, ingrese la dirección de envío' }]}
                  >
                    <Input.TextArea rows={3} />
                  </Form.Item>
                  {/* Checkbox для подтверждения отправки данных */}
                  <Form.Item>
                    <Checkbox checked={isAgreed} onChange={(e) => setIsAgreed(e.target.checked)}>
                      <CheckCircleOutlined style={{ color: 'green', marginRight: '8px' }} />
                      Estoy de acuerdo con el envío de mis datos
                    </Checkbox>
                  </Form.Item>
                  <Form.Item>
                    <Button type="primary" htmlType="submit" style={{ width: '100%' }} disabled={!isAgreed || loading} loading={loading}>
                      Realizar Pedido
                    </Button>
                  </Form.Item>
                </Form>
              ) : (
                <div style={{ textAlign: 'center' }}>
                  <CheckCircleOutlined style={{ fontSize: '48px', color: 'green' }} />
                  <Title level={3}>¡Pedido Realizado!</Title>
                  <Paragraph>Gracias por su compra, su pedido ha sido enviado con éxito.</Paragraph>
                </div>
              )}
            </Col>
          </Row>
        </div>
      </Content>
    </Layout>
  );
};

export default ProductOrder;
