// src/pages/Shop.js

import React from 'react';
import { Layout, Card, Typography, Row, Col, Button } from 'antd';
import { Helmet } from 'react-helmet';
import { useNavigate } from 'react-router-dom'; // Importamos useNavigate en lugar de useHistory
import img1 from '../components/img/photo_2024-09-24_12-29-36.jpg';
import img2 from '../components/img/photo_2024-09-24_14-27-09.jpg';
import img3 from '../components/img/photo_2024-09-30_15-45-10.jpg';

const { Content } = Layout;
const { Title, Paragraph } = Typography;

const Shop = () => {
  const navigate = useNavigate(); // Usamos useNavigate para la navegación
  const products = [
    {
      id: 1,
      name: 'Proteínas Naturales',
      description: 'Suplemento de proteínas naturales para mejorar el rendimiento y la recuperación.',
      price: '35€',
      image: img1,
    },
    {
      id: 2,
      name: 'Vitaminas para Energía',
      description: 'Complejo multivitamínico diseñado para aumentar los niveles de energía durante el día.',
      price: '25€',
      image: img2,
    },
    {
      id: 3,
      name: 'Batido Detox',
      description: 'Batido detoxificante hecho a base de ingredientes naturales para un cuerpo saludable.',
      price: '15€',
      image: img3,
    },
  ];

  const navigateToProductOrder = (id) => {
    navigate(`/shop/${id}/order`); // Usamos navigate en lugar de history.push
  };

  return (
    <Layout className="layout">
      <Helmet>
        <title>Productos</title>
        <meta name="description" content="Compra productos para la salud y la energía" />
      </Helmet>

      <Content style={{ padding: '', background: '#f0f2f5' }}>
        <div className="site-layout-content" style={{ padding: '', maxWidth: '1200px', margin: '0 auto' }}>
          <Title level={1} style={{ textAlign: 'center', marginBottom: '40px' }}>Productos para la salud y la energía</Title>

          <Row gutter={[16, 16]} justify="center">
            {products.map((product) => (
              <Col xs={24} sm={12} md={8} lg={6} key={product.id}>
                <Card
                  hoverable
                  cover={<img alt={product.name} src={product.image} style={{ height: '200px', objectFit: 'cover' }} />}
                  onClick={() => navigateToProductOrder(product.id)} // Navegar a la página de pedido al hacer clic en la tarjeta
                >
                  <Title level={3}>{product.name}</Title>
                  <Paragraph>{product.description}</Paragraph>
                  <Paragraph><strong>Precio: {product.price}</strong></Paragraph>
                  <Button type="primary" block onClick={() => navigateToProductOrder(product.id)}>
                    Pedir
                  </Button>
                </Card>
              </Col>
            ))}
          </Row>
        </div>
      </Content>
    </Layout>
  );
};

export default Shop;
