// src/pages/Home.js

import React from 'react';
import { Helmet } from 'react-helmet';
import { Layout, Button, Typography, Row, Col } from 'antd';
import { Link } from 'react-router-dom';
import Header from './components/Header';  // Импортируем компонент Header
import Footer from './components/Footer';  // Импортируем компонент Footer
import img1 from './components/img/photo_2024-09-24_12-29-36.jpg';
import img2 from './components/img/photo_2024-09-24_14-27-09.jpg';
import img3 from './components/img/photo_2024-09-30_15-45-10.jpg';

const { Content } = Layout;
const { Title, Paragraph } = Typography;

const Home = () => {
  return (
    <Layout className="layout">
      <Helmet>
        <title>Taller: Nutrición para la energía y la armonía</title>
        <meta name="description" content="Descubre los fundamentos de la nutrición para el equilibrio y la energía en este taller." />
      </Helmet>

      <Content style={{ padding: '0 20px', background: '#f0f2f5' }}>
        <div className="site-layout-content" style={{ margin: '0 auto', padding: '30px 15px', maxWidth: '1200px' }}>
          <Title level={1} style={{ textAlign: 'center', marginBottom: '20px' }}>Taller: Nutrición para la energía y la armonía</Title>
          <Paragraph style={{ fontSize: '18px', textAlign: 'center', maxWidth: '800px', margin: '0 auto', marginBottom: '40px' }}>
            La nutrición es clave para mantener el equilibrio y la energía. Descubre cómo mejorar tu dieta y bienestar general.
          </Paragraph>

          <div style={{ textAlign: 'center', marginBottom: '30px' }}>
            <Link to="/shop">
              <Button type="primary" size="large" style={{ marginRight: '20px', marginBottom: '10px' }}>Market</Button>
            </Link>
            <Link to="/about">
              <Button type="default" size="large" style={{ marginRight: '20px', marginBottom: '10px' }}>Sobre nosotros</Button>
            </Link>
            <Link to="/contact">
              <Button type="default" size="large">Contactar</Button>
            </Link>
          </div>
        </div>

        {/* Адаптивные секции с более подробной информацией и изображениями */}
        <div className="detailed-info" style={{ marginTop: '30px' }}>
          <Row gutter={[16, 16]} justify="center">
            <Col xs={24} md={12} lg={10}>
              <Title level={2}>La influencia de los alimentos en el bienestar</Title>
              <Paragraph style={{ fontSize: '16px' }}>
                Los alimentos que consumimos no solo afectan nuestra salud física, sino también nuestro bienestar emocional y mental. 
                Alimentos ricos en nutrientes y vitaminas pueden mejorar tu estado de ánimo, concentración y reducir el estrés.
              </Paragraph>
              <Paragraph style={{ fontSize: '16px' }}>
                Algunos ejemplos incluyen el pescado, las nueces y las frutas frescas, que son conocidas por mejorar la concentración y aumentar la energía.
              </Paragraph>
            </Col>
            <Col xs={24} md={12} lg={10}>
              <img 
                src={img1} 
                alt="Alimentos para el bienestar" 
                style={{ width: '100%', borderRadius: '10px' }} 
              />
            </Col>
          </Row>
        </div>

        <div className="detailed-info" style={{ marginTop: '30px' }}>
          <Row gutter={[16, 16]} justify="center">
            <Col xs={24} md={12} lg={10}>
              <img 
                src={img2} 
                alt="Alimentos para la armonía y energía" 
                style={{ width: '100%', borderRadius: '10px' }} 
              />
            </Col>
            <Col xs={24} md={12} lg={10}>
              <Title level={2}>Alimentos para la armonía y energía</Title>
              <Paragraph style={{ fontSize: '16px' }}>
                Comer una dieta equilibrada llena de alimentos naturales, como frutas, verduras y proteínas magras, 
                puede ayudarte a sentirte con energía durante todo el día y mantener el equilibrio interno.
              </Paragraph>
              <Paragraph style={{ fontSize: '16px' }}>
                Incluir granos integrales, vegetales de hojas verdes y proteínas de origen vegetal o animal es esencial 
                para tener una dieta rica en nutrientes que apoye la energía y el bienestar general.
              </Paragraph>
            </Col>
          </Row>
        </div>

        <div className="detailed-info" style={{ marginTop: '30px' }}>
          <Row gutter={[16, 16]} justify="center">
            <Col xs={24} md={12} lg={10}>
              <Title level={2}>Reglas sencillas para equilibrar el agua</Title>
              <Paragraph style={{ fontSize: '16px' }}>
                El agua es esencial para el bienestar físico y mental. Beber suficiente agua cada día ayuda a mantener la energía 
                y el tono vital, además de mejorar la concentración y la salud de la piel.
              </Paragraph>
              <Paragraph style={{ fontSize: '16px' }}>
                Se recomienda beber al menos 2 litros de agua al día, pero esto puede variar según la actividad física y el clima.
              </Paragraph>
            </Col>
            <Col xs={24} md={12} lg={10}>
              <img 
                src={img3}
                alt="Agua para la energía" 
                style={{ width: '100%', borderRadius: '10px' }} 
              />
            </Col>
          </Row>
        </div>
      </Content>
    </Layout>
  );
};

export default Home;
