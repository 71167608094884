import React, { useState } from 'react';
import { Layout, Form, Input, Button, Typography, Checkbox, message } from 'antd';
import { Helmet } from 'react-helmet';
import { CheckCircleOutlined } from '@ant-design/icons';

const { Content } = Layout;
const { Title, Paragraph } = Typography;

const Contact = () => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isAgreed, setIsAgreed] = useState(false); // Состояние для согласия пользователя

  // Telegram Bot Token и Chat ID (замените на ваши реальные данные)
  const TELEGRAM_BOT_TOKEN = '8191992355:AAGaaFZh07BmEeJzOrjKTzFXAqLwjzGfL9c';
  const TELEGRAM_CHAT_ID = '-4540973525';

  const handleSubmit = (values) => {
    setLoading(true);

    // Формирование сообщения для отправки в Telegram
    const messageText = `
      📝 *Новая заявка с сайта* 📝
      👤 *Имя*: ${values.name}
      📧 *Email*: ${values.email}
      💬 *Сообщение*: ${values.message}
    `;

    // Отправка данных в Telegram через API
    fetch(`https://api.telegram.org/bot${TELEGRAM_BOT_TOKEN}/sendMessage`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        chat_id: TELEGRAM_CHAT_ID,
        text: messageText,
        parse_mode: 'Markdown', // Чтобы сделать текст форматированным
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.ok) {
          message.success('¡Su mensaje ha sido enviado con éxito!');
          setIsSubmitted(true);
          form.resetFields(); // Сбрасываем поля формы
        } else {
          message.error('Ошибка при отправке сообщения в Telegram.');
        }
        setLoading(false);
      })
      .catch((error) => {
        console.error('Ошибка:', error);
        message.error('Произошла ошибка при отправке сообщения.');
        setLoading(false);
      });
  };

  return (
    <Layout className="layout">
      <Helmet>
        <title>Contacto</title>
        <meta name="description" content="Póngase en contacto con nosotros para obtener más información" />
      </Helmet>

      <Content style={{ padding: '0 50px', background: '#f0f2f5' }}>
        <div className="site-layout-content" style={{ padding: '30px 15px', maxWidth: '800px', margin: '0 auto' }}>
          <Title level={1} style={{ textAlign: 'center', marginBottom: '40px' }}>Contáctenos</Title>
          <Paragraph style={{ textAlign: 'center', marginBottom: '40px' }}>
            Si tiene preguntas o desea obtener más información, no dude en contactarnos. ¡Le responderemos lo antes posible!
          </Paragraph>

          {!isSubmitted ? (
            <Form
              form={form}
              layout="vertical"
              onFinish={handleSubmit}
              style={{ background: '#fff', padding: '20px', borderRadius: '8px' }}
            >
              <Form.Item
                name="name"
                label="Su nombre"
                rules={[{ required: true, message: 'Por favor, ingrese su nombre' }]}
              >
                <Input placeholder="Ingrese su nombre" />
              </Form.Item>

              <Form.Item
                name="email"
                label="Su correo electrónico"
                rules={[{ required: true, type: 'email', message: 'Por favor, ingrese un correo electrónico válido' }]}
              >
                <Input placeholder="Ingrese su correo electrónico" />
              </Form.Item>

              <Form.Item
                name="message"
                label="Mensaje"
                rules={[{ required: true, message: 'Por favor, ingrese su mensaje' }]}
              >
                <Input.TextArea rows={4} placeholder="Ingrese su mensaje" />
              </Form.Item>

              {/* Checkbox для подтверждения согласия с отправкой данных */}
              <Form.Item>
                <Checkbox checked={isAgreed} onChange={(e) => setIsAgreed(e.target.checked)}>
                  <CheckCircleOutlined style={{ color: 'green', marginRight: '8px' }} />
                  Estoy de acuerdo con el envío de mis datos
                </Checkbox>
              </Form.Item>

              <Form.Item>
                <Button type="primary" htmlType="submit" loading={loading} disabled={!isAgreed} style={{ width: '100%' }}>
                  Enviar mensaje
                </Button>
              </Form.Item>
            </Form>
          ) : (
            <div style={{ textAlign: 'center' }}>
              <CheckCircleOutlined style={{ fontSize: '48px', color: 'green', marginBottom: '20px' }} />
              <Title level={2}>¡Mensaje Enviado!</Title>
              <Paragraph>Gracias por contactarnos. Nos pondremos en contacto con usted lo antes posible.</Paragraph>
            </div>
          )}
        </div>
      </Content>
    </Layout>
  );
};

export default Contact;
