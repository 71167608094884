import React from 'react';
import { HashRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './Home';
import Seminars from './Seminars';
import About from './About';
import Contact from './components/Contact';
import Register from './Register';
import Header from './components/Header';
import Footer from './components/Footer';
import { Layout } from 'antd';
import Shop from './pages/Shop';
import ProductOrder from './components/ProductOrder';
import PrivacyPolicy from './pages/PrivacyPolicy';
import Policies from './pages/Policies';
import DataSecurityPolicy from './components/DataSecurityPolicy';
import CookiePolicy from './components/CookiePolicy';


const { Content } = Layout;

const App = () => {
  return (
    <Router>
      <Layout className="layout">
        <Header />
        <Content style={{ padding: '0 50px', background: '#f0f2f5', minHeight: '80vh' }}>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/seminars" element={<Seminars />} />
            <Route path="/about" element={<About />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/register" element={<Register />} />
            <Route path="/shop" element={<Shop />} />
            <Route path="/shop/:id/order" element={<ProductOrder />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy/>} />
            <Route path="/policy" element={<Policies></Policies>} />
            <Route path="/datasecuritypolicy" element={<DataSecurityPolicy/>} />
            <Route path="/cookiepolicy" element={<CookiePolicy></CookiePolicy>} />
          </Routes>
        </Content>
        <Footer />
      </Layout>
    </Router>
  );
};

export default App;
