// src/components/Header.js

import React from 'react';
import { Layout, Menu } from 'antd';
import { Link } from 'react-router-dom';
import { ReadOutlined, InfoCircleOutlined, ContactsOutlined, ShoppingCartOutlined } from '@ant-design/icons';

const { Header: AntHeader } = Layout;

const Header = () => {
  return (
    <AntHeader>
      <div className="logo" />
      <Menu theme="dark" mode="horizontal" defaultSelectedKeys={['1']}>
        <Menu.Item key="1" icon={<ReadOutlined />}>
          <Link to="/">Temas del Taller</Link>
        </Menu.Item>
        <Menu.Item key="2" icon={<InfoCircleOutlined />}>ФС
          <Link to="/about">Sobre nosotros</Link>
        </Menu.Item>
        <Menu.Item key="3" icon={<ContactsOutlined />}>
          <Link to="/contact">Contactar</Link>
        </Menu.Item>
        {/* Новая страница */}
        <Menu.Item key="4" icon={<ShoppingCartOutlined />}>
          <Link to="/shop">Tienda</Link> {/* Ссылка на страницу магазина */}
        </Menu.Item>
      </Menu>
    </AntHeader>
  );
};

export default Header;
