import React from 'react';
import { Helmet } from 'react-helmet';
import { Typography, Divider } from 'antd';

const { Title, Paragraph } = Typography;

const PrivacyPolicy = () => {
  return (
    <div>
      <Helmet>
        <title>Política de privacidad</title>
        <meta name="description" content="Lee nuestra política de privacidad para entender cómo manejamos tus datos." />
      </Helmet>

      <Title level={2}>Política de privacidad</Title>
      <Divider />
      <Paragraph>
        Esta política de privacidad describe cómo recopilamos, usamos y protegemos la información personal que nos proporcionas al usar nuestro sitio web y servicios.
      </Paragraph>

      <Title level={3}>Datos que recopilamos</Title>
      <Paragraph>
        Podemos recopilar los siguientes datos personales:
      </Paragraph>
      <ul>
        <li>Nombre completo</li>
        <li>Dirección de correo electrónico</li>
        <li>Dirección de envío (en caso de compras)</li>
        <li>Número de teléfono</li>
        <li>Información de pago (cuando sea necesario para procesar transacciones)</li>
      </ul>

      <Title level={3}>Uso de cookies</Title>
      <Paragraph>
        Utilizamos cookies y tecnologías similares para mejorar la experiencia de usuario en nuestro sitio web, analizar el tráfico y personalizar el contenido. Puedes controlar el uso de cookies a través de la configuración de tu navegador.
      </Paragraph>

      <Title level={3}>Cómo usamos tus datos</Title>
      <Paragraph>
        Utilizamos la información personal recopilada para:
      </Paragraph>
      <ul>
        <li>Procesar y gestionar tus pedidos</li>
        <li>Proporcionar asistencia al cliente</li>
        <li>Enviar actualizaciones sobre nuestros productos, seminarios y eventos</li>
        <li>Personalizar tu experiencia en nuestro sitio web</li>
      </ul>

      <Title level={3}>Transferencia internacional de datos</Title>
      <Paragraph>
        Tus datos personales pueden ser transferidos y procesados en servidores ubicados fuera de tu país de residencia. Tomamos todas las medidas necesarias para garantizar que tus datos estén protegidos de acuerdo con esta política de privacidad.
      </Paragraph>

      <Title level={3}>Retención de datos</Title>
      <Paragraph>
        Retenemos tus datos personales solo durante el tiempo necesario para cumplir con los propósitos para los cuales fueron recopilados, o según lo requiera la ley.
      </Paragraph>

      <Title level={3}>Protección de tus datos</Title>
      <Paragraph>
        Implementamos medidas de seguridad adecuadas para proteger tus datos personales contra el acceso no autorizado, la alteración o la destrucción de la información.
      </Paragraph>

      <Title level={3}>Compartir tus datos</Title>
      <Paragraph>
        No compartimos, vendemos ni intercambiamos tus datos personales con terceros, excepto en los siguientes casos:
      </Paragraph>
      <ul>
        <li>Cuando sea necesario para cumplir con la ley</li>
        <li>Cuando sea necesario para procesar pagos o entregar pedidos</li>
        <li>Si damos acceso a proveedores de servicios que nos ayudan a gestionar nuestras operaciones</li>
      </ul>

      <Title level={3}>Tus derechos</Title>
      <Paragraph>
        Tienes derecho a acceder, rectificar o eliminar tus datos personales en cualquier momento. Si deseas ejercer alguno de estos derechos, por favor contáctanos.
      </Paragraph>
      <Paragraph>
        Además de los derechos mencionados, si resides en el Espacio Económico Europeo (EEE), tienes los siguientes derechos adicionales bajo el Reglamento General de Protección de Datos (GDPR):
      </Paragraph>
      <ul>
        <li>Derecho a la portabilidad de los datos</li>
        <li>Derecho a la limitación del tratamiento</li>
        <li>Derecho a presentar una reclamación ante una autoridad de control</li>
      </ul>

      <Title level={3}>Política para menores</Title>
      <Paragraph>
        Nuestro sitio web no está destinado a ser utilizado por menores de 13 años, y no recopilamos intencionalmente información personal de menores sin el consentimiento de los padres.
      </Paragraph>

      <Title level={3}>Cambios en la política de privacidad</Title>
      <Paragraph>
        Nos reservamos el derecho de modificar esta política de privacidad en cualquier momento. Te notificaremos sobre cualquier cambio a través de nuestro sitio web o por correo electrónico.
      </Paragraph>

      <Title level={3}>Contacto</Title>
      <Paragraph>
        Si tienes alguna pregunta sobre esta política de privacidad o sobre el manejo de tus datos personales, puedes contactarnos a través de la siguiente dirección de correo electrónico: [correo@example.com]
      </Paragraph>

      <Divider />
      <Paragraph>Última actualización: Septiembre de 2023</Paragraph>
    </div>
  );
}

export default PrivacyPolicy;
