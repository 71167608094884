import React from 'react';
import { Layout, Typography, Divider } from 'antd';
import { Helmet } from 'react-helmet';

const { Title, Paragraph } = Typography;
const { Content } = Layout;

const CookiePolicy = () => {
  return (
    <Layout className="layout">
      <Helmet>
        <title>Política de Cookies</title>
        <meta name="description" content="Lee nuestra política de cookies para entender cómo usamos las cookies en nuestro sitio web." />
      </Helmet>

      <Content style={{ padding: '30px', background: '#f0f2f5' }}>
        <div className="site-layout-content" style={{ maxWidth: '1200px', margin: '0 auto' }}>
          
          <Title level={2}>Política de Cookies</Title>
          <Divider />
          <Paragraph>
            En nuestro sitio web, utilizamos cookies para mejorar la experiencia del usuario, analizar el tráfico y personalizar el contenido y los anuncios.
            Las cookies son pequeños archivos de texto que se almacenan en su dispositivo cuando visita un sitio web. Estas cookies nos ayudan a recordar sus preferencias y mejorar su experiencia en nuestro sitio.
          </Paragraph>

          <Title level={3}>¿Qué tipo de cookies utilizamos?</Title>
          <Paragraph>
            Utilizamos varios tipos de cookies en nuestro sitio web:
          </Paragraph>
          <ul>
            <li><strong>Cookies necesarias:</strong> Estas cookies son esenciales para que el sitio web funcione correctamente y no pueden desactivarse.</li>
            <li><strong>Cookies de rendimiento:</strong> Nos ayudan a entender cómo los usuarios interactúan con el sitio web recopilando información anónima.</li>
            <li><strong>Cookies de funcionalidad:</strong> Estas cookies permiten personalizar el sitio según sus elecciones anteriores, como su idioma o región.</li>
            <li><strong>Cookies de publicidad:</strong> Se utilizan para mostrar anuncios relevantes para usted según sus intereses.</li>
          </ul>

          <Title level={3}>¿Cómo controlar las cookies?</Title>
          <Paragraph>
            Puede controlar y gestionar el uso de cookies a través de la configuración de su navegador. Tenga en cuenta que la desactivación de ciertas cookies puede afectar la funcionalidad del sitio web.
            Consulte las instrucciones de su navegador para saber cómo desactivar las cookies.
          </Paragraph>

          <Divider />
          <Title level={3}>Contáctenos</Title>
          <Paragraph>
            Si tiene alguna pregunta sobre nuestra política de cookies o sobre cómo usamos las cookies en nuestro sitio web, puede contactarnos a través de los siguientes medios:
          </Paragraph>
          <ul>
            <li><strong>Teléfono:</strong> +34 123 456 789</li>
            <li><strong>Correo electrónico:</strong> cookies@nutricionenergia.com</li>
            <li><strong>Dirección:</strong> Calle Falsa 123, Madrid, España</li>
          </ul>
        </div>
      </Content>
    </Layout>
  );
};

export default CookiePolicy;
