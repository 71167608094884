import React from 'react';
import { Layout, Typography, Divider } from 'antd';
import { Helmet } from 'react-helmet';

const { Title, Paragraph } = Typography;
const { Content } = Layout;

const DataSecurityPolicy = () => {
  return (
    <Layout className="layout">
      <Helmet>
        <title>Política de Seguridad de los Datos</title>
        <meta name="description" content="Lee nuestra política de seguridad de datos y cómo protegemos su información." />
      </Helmet>

      <Content style={{ padding: '30px', background: '#f0f2f5' }}>
        <div className="site-layout-content" style={{ maxWidth: '1200px', margin: '0 auto' }}>
          <Title level={2}>Política de Seguridad de los Datos</Title>
          <Divider />
          <Paragraph>
            En nuestro sitio, nos tomamos muy en serio la seguridad de sus datos. Implementamos medidas técnicas y organizativas adecuadas para proteger su información personal contra el acceso no autorizado, la alteración, divulgación o destrucción.
          </Paragraph>

          <Title level={3}>¿Qué medidas tomamos para proteger sus datos?</Title>
          <Paragraph>
            Empleamos tecnologías avanzadas como el cifrado SSL/TLS para proteger las transferencias de datos y garantizar que su información esté segura mientras se comunica con nuestro sitio. Además, limitamos el acceso a sus datos personales solo a aquellos empleados y terceros que necesitan dicha información para procesar sus pedidos.
          </Paragraph>

          <Title level={3}>Protección contra ataques</Title>
          <Paragraph>
            Contamos con sistemas de monitoreo para prevenir ataques maliciosos y evitar brechas de seguridad. Si, a pesar de nuestras medidas, ocurre una violación de seguridad, le informaremos de inmediato y tomaremos todas las medidas necesarias para proteger sus datos.
          </Paragraph>

          <Title level={3}>Sus derechos</Title>
          <Paragraph>
            Tiene derecho a solicitar acceso, corrección o eliminación de sus datos en cualquier momento. Si tiene alguna inquietud sobre la seguridad de sus datos, no dude en contactarnos utilizando la información de contacto a continuación.
          </Paragraph>

          <Divider />
          <Title level={3}>Contáctenos</Title>
          <Paragraph>
            Si tiene preguntas sobre nuestra política de seguridad de datos o sobre cómo protegemos su información personal, puede contactarnos a través de:
          </Paragraph>
          <ul>
            <li><strong>Teléfono:</strong> +34 123 456 789</li>
            <li><strong>Correo electrónico:</strong> seguridad@nutricionenergia.com</li>
            <li><strong>Dirección:</strong> Calle Falsa 123, Madrid, España</li>
          </ul>
        </div>
      </Content>
    </Layout>
  );
};

export default DataSecurityPolicy;
