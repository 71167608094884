// src/pages/About.js

import React from 'react';
import { Helmet } from 'react-helmet';
import { Typography, Divider } from 'antd';

const { Title, Paragraph } = Typography;

const About = () => {
  return (
    <div>
      <Helmet>
        <title>Sobre nosotros</title>
        <meta name="description" content="Conoce más sobre nuestro equipo y nuestra misión." />
      </Helmet>

      <Title level={2}>Sobre nosotros</Title>
      <Divider />
      <Paragraph>
        En nuestra organización, nos especializamos en ofrecer seminarios y talleres dedicados al bienestar integral, combinando el conocimiento científico con enfoques prácticos.
      </Paragraph>
      <Paragraph>
        Nuestro equipo está formado por expertos en nutrición y bienestar que están comprometidos en ayudarte a mejorar tu calidad de vida a través de una nutrición equilibrada.
      </Paragraph>
      <Paragraph>
        Además de nuestra experiencia en el ámbito de la nutrición, también colaboramos con profesionales de la salud mental y el fitness para brindarte un enfoque completo de bienestar.
      </Paragraph>
      <Paragraph>
        Nuestra misión es hacer accesibles los conocimientos sobre salud y bienestar a personas de todo el mundo, ayudándolas a alcanzar un equilibrio saludable en sus vidas diarias.
      </Paragraph>
      <Paragraph>
        A través de nuestros seminarios, aprenderás cómo pequeños cambios en tu dieta y estilo de vida pueden tener un impacto positivo en tu salud general, mejorando tu energía y bienestar mental.
      </Paragraph>
      <Divider />
      <Title level={3}>Nuestra visión</Title>
      <Paragraph>
        Creemos que el bienestar es un derecho universal, y nos esforzamos por ofrecer educación accesible que ayude a las personas a tomar decisiones informadas sobre su salud y nutrición.
      </Paragraph>
    </div>
  );
}

export default About;
