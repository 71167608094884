import React from 'react';
import { Layout, Typography, Divider } from 'antd';
import { Helmet } from 'react-helmet';

const { Title, Paragraph } = Typography;
const { Content } = Layout;

const Policies = () => {
  return (
    <Layout className="layout">
      <Helmet>
        <title>Política de Devolución y Política de Cookies</title>
        <meta name="description" content="Lee nuestra política de devolución y política de cookies para entender nuestros términos." />
      </Helmet>

      <Content style={{ padding: '30px', background: '#f0f2f5' }}>
        <div className="site-layout-content" style={{ maxWidth: '1200px', margin: '0 auto' }}>
          <Title level={2}>Política de Devolución y Reembolso</Title>
          <Divider />

          <Paragraph>
            En nuestra tienda, queremos asegurarnos de que estés completamente satisfecho con tu compra. Si por alguna razón no estás satisfecho con el producto,
            puedes devolverlo siguiendo las condiciones establecidas a continuación.
          </Paragraph>

          <Title level={3}>Condiciones para Devoluciones</Title>
          <Paragraph>
            Puedes devolver el producto dentro de los 30 días posteriores a la entrega. El producto debe estar sin usar, en su embalaje original y en las mismas condiciones en que lo recibiste.
            Los costos de envío de la devolución corren a tu cargo, salvo que el producto tenga defectos o haya sido enviado incorrectamente.
          </Paragraph>

          <Title level={3}>Proceso de Reembolso</Title>
          <Paragraph>
            Una vez que recibamos tu devolución, revisaremos el estado del producto. Si todo está en orden, procesaremos el reembolso a tu método de pago original.
            El proceso de reembolso puede tardar entre 5-7 días hábiles desde la fecha de confirmación.
          </Paragraph>

          <Divider />

          <Title level={2}>Política de Cookies</Title>
          <Divider />

          <Paragraph>
            Utilizamos cookies para mejorar la experiencia de nuestros usuarios en el sitio web. Las cookies nos permiten analizar el tráfico, personalizar el contenido y 
            ofrecer una mejor experiencia en general.
          </Paragraph>

          <Title level={3}>¿Qué son las Cookies?</Title>
          <Paragraph>
            Las cookies son pequeños archivos de texto que se almacenan en tu dispositivo cuando visitas un sitio web. Nos ayudan a recordar tu actividad anterior, 
            lo que nos permite ofrecerte una experiencia personalizada.
          </Paragraph>

          <Title level={3}>Tipos de Cookies que Utilizamos</Title>
          <Paragraph>
            Usamos varios tipos de cookies en nuestro sitio:
          </Paragraph>
          <ul>
            <li><strong>Cookies funcionales:</strong> Estas cookies son esenciales para que el sitio web funcione correctamente. Permiten funciones básicas como la navegación en la página y el acceso a áreas seguras.</li>
            <li><strong>Cookies de rendimiento:</strong> Estas cookies nos ayudan a entender cómo los usuarios interactúan con el sitio web recopilando información anónima sobre las páginas visitadas.</li>
            <li><strong>Cookies de marketing:</strong> Estas cookies se utilizan para mostrar anuncios que son relevantes para los intereses del usuario.</li>
          </ul>

          <Title level={3}>Gestión de Cookies</Title>
          <Paragraph>
            Tienes la opción de aceptar o rechazar las cookies en cualquier momento. La mayoría de los navegadores permiten controlar el uso de cookies a través de sus configuraciones. Si decides bloquear cookies,
            es posible que algunas funciones de nuestro sitio no funcionen correctamente.
          </Paragraph>

          <Divider />
          <Paragraph>Última actualización: Octubre 2023</Paragraph>
        </div>
      </Content>
    </Layout>
  );
};

export default Policies;
